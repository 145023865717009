@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

:root {
  --secondary: rgba(201, 228, 255, 0.475);
  /* --secondary: #c9e4ff79 */
  --primary: rgb(66, 160, 255);
  /* --primary: #42a0ff; */
  /* --shadow: black 15px 15px 0px -3px; */
  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

* {
  margin: 0;
  font-weight: 300;
  -webkit-tap-highlight-color: transparent;
  padding: 0;
  outline: none;
  border: none;
  font-family: "Roboto";
  box-sizing: border-box;
  text-decoration: none;
  color: rgb(54, 54, 54);
  z-index: 2;
  border-radius: 8px;
}

body,
html {
  height: 100%;
  width: 100%;
}

input {
  border-radius: 0;
}

body {
  background-color: var(--secondary);
  padding: 15px;
  background-image: url("./images/tilt.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.hidden {
  display: none;
}

li {
  list-style: none;
}

hr {
  width: 100%;
  height: 2px;
  background-color: black;
}

.error {
  color: rgb(255, 68, 68);
  margin-top: 10px;
  padding-left: 10px;
  font-size: 12px;
}

.sub-text-for-black {
  font-size: 18px;
  color: rgb(248, 248, 248);
  font-weight: 400;
}

.sub-text-for-white {
  font-size: 18px;
  color: rgb(122, 122, 122);
  font-weight: 400;
}

.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 15px 0px;
}

.btn {
  width: 100%;
  padding: 10px;
  height: 50px;
  margin-top: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--primary);
  color: white;
  font-size: 18px;
  text-transform: Uppercase;
}

.icon {
  height: 24px;
  width: 24px;
  box-sizing: border-box;
  margin: 0 8px;
  transition: all 150ms ease-in-out;
}

.icon:active {
  transform: scale(0.8);
}

.label {
  font-size: 12px;
  margin-top: 30px;
  color: var(--primary);
}

.box {
  height: 100%;
}

.nav {
  width: calc(100%);
  height: 64px;
}

.logo {
  height: 40px;
}

.nav ul {
  height: 100%;
  /* background-color: white; */
  background: none;
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-item {
  text-decoration: none;
  color: white;
  background-color: black;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 14px;
  margin-left: 10px;
}

.footer {
  position: fixed;
  height: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0;
  justify-content: center;
  font-size: 14px;
  background-color: white;
  color: var(--primary);
  display: flex;
  flex-direction: column;
}

.heroHeading {
  background-color: white;
  font-size: 50px;
  font-weight: 900;
  box-shadow: var(--shadow);
  text-align: left;
  margin-top: 30px;
  line-height: 1.1;
  padding: 20px;
}

.heroHeading span {
  font-weight: 900;
  color: var(--primary);
}

.heroText {
  text-align: left;
  line-height: 1.7;
  padding: 5px 0;
  margin: 40px 0;
  font-size: 18px;
  font-weight: 400;
  color: black;
}

.anim {
  width: calc(100%);
  position: absolute;
  left: 0;
  margin-top: 55px;
  opacity: 0.2;
  z-index: -900;
}

.inputArea {
  font-size: 18px;
}

.textArea {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  align-items: center;
  padding: 20px;
  height: 65px;
}

.textArea span {
  font-size: 20px;
}

.nameInput {
  color: var(--primary);
  font-size: 18px;
  width: 100%;
  height: 65px;
}

.btnClaim {
  height: 60px;
  font-size: 16px;
  width: fit-content;
  padding: 20px;
  float: right;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
}

.parent {
  padding: 12px 0;
}

.copyIcon {
  background-color: rgb(255, 169, 169);
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}

.copyIcon img {
  height: 12px;
  width: 12px;
  border-radius: 0;
}

.firsCard {
  padding: 30px;
  box-shadow: var(--shadow);
  background-color: rgba(255, 255, 255, 0.93);
}

.bgContainer {
  margin-bottom: 40px;
  /* background-image: url("./images/profile.gif"); */
  background-position: -70%;
  opacity: 0.85;
}

.firsCard hr {
  width: 40px;
  margin-top: 30px;
  margin-bottom: 35px;
  height: 2px;
  background-color: var(--secondary);
}

.username {
  color: black;
  width: fit-content;
  font-weight: 500;
}

.name {
  font-size: 50px;
  font-weight: 900;
  margin-top: 0px;
  color: var(--primary);
}

.bio {
  margin-top: 0px;
  font-size: 14px;
  background-color: var(--secondary);
  color: var(--primary);
  width: fit-content;
  padding: 2px 7px;
  border-radius: 50px;
}

.contactCard {
  border-radius: 0;
  margin-left: -7px;
}

.contactCard img {
  border-radius: 0;
  height: 20px;
  width: 20px;
  padding: 0;
}

.contactCard h3 {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 400;
}

.topFive {
  margin-top: 15px;
  padding: 0;
  justify-content: flex-start;
}

.social {
  height: 40px;
  width: 40px;
  margin: 0;
  margin-right: 25px;
}

.extraLinks {
  padding-bottom: 120px;
}

.extraLinks li a {
  width: 100%;
  height: 90px;
  background-color: white;
  box-sizing: border-box;
  padding-right: 20px;
  margin-bottom: 1px;
  display: flex;
  /* box-shadow: var(--shadow); */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  justify-content: space-between;
  align-items: center;
}

.extraLinks .icon {
  height: 90px;
  width: 90px;
  margin: 0;
  box-sizing: border-box;
}

.extraLinks .arrow {
  height: 20px;
  width: 20px;
  background: none;
  padding: 0;
  border-radius: 0;
}

.linkDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 20px;
}

.linkName {
  font-weight: 600;
  text-align: left;
  width: 100%;
  font-size: 18px;
}

.linkDesc {
  width: 100%;
  text-align: left;
  color: rgb(125, 125, 125);
  font-size: 14px;
  margin-top: 10px;
}

.spotify {
  margin-top: 8px;
}

.btnAdd {
  width: 100px;
  background: none;
  right: 15px;
  bottom: 50px;
  position: fixed;
  cursor: pointer;
  transition: all 250ms ease-in-out;
}

.btnAdd:hover {
  transform: scale(0.9);
}

.btnAdd:active {
  transform: scale(0.8);
}

.signupContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.lottie {
  width: 120px;
  margin-left: -30px;
}

.signupCard {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 15px;
  background-color: white;
  margin: 30px 0px 60px 0px;
  box-shadow: var(--shadow);
}

.signupContainer h1 {
  font-size: 50px;
  font-weight: 900;
  margin-bottom: 10px;
  color: var(--primary);
}

.signupContainer h3 {
  color: var(--primary);
  margin-top: 20px;
  margin-bottom: 20px;
}

.signupContainer hr {
  margin-left: 20px;
  margin-right: 20px;
  width: 50%;
}

.signupContainer form {
  display: flex;
  flex-direction: column;
}

.signupContainer input {
  border: none;
  border-bottom: 1px solid rgb(210, 210, 210);
  height: 40px;
  padding: 20px 0px;
  font-size: 18px;
  box-sizing: border-box;
}

.passError {
  color: gray;
}

.btn:hover {
  border: none;
  background-color: black;
}

.builtInSocial {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: white;
  padding: 12px;
  box-shadow: var(--shadow);
}

.builtInSocial li img {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

/* ****************************************************************************************************************************** */
@media only screen and (min-width: 500px) {
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .route-container {
    width: 500px;
    align-self: center;
    border: 1px solid var(--primary);
    border-radius: 18px;
    margin-top: 20px;
    padding: 0 30px 30px 30px;
  }

  .signupContainer {
    width: 100%;
  }

  .nav {
    width: 500px;
    height: 65px;
    box-shadow: none;
  }
}
